import React from 'react'
import Layout from '../../components/layout/layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarSeThree from "../../components/layout/sidemenu/sidemenu_se_three"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import ContentHeader from '../../components/page-sections/content-header'
import ButtonNext from '../../components/buttons/button-next'

import rowImage from '../../queries/images/row-image'

import unit3Icon from '../../images/icons/unit_03_icon.svg'


const Unit3Overview = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - Unit 3 Overview" />
    <Container className="mb-5" fluid>
      <Row>
        <Col md="auto">
          <SideBarSeThree location={props.location.pathname} />
        </Col>
        <Col>
          <CCBreadcrumb
            pathname={props.location.pathname}
            title={'3.0 Overview'}
            replace={props.replace}
          />
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
          <Card>
            <Card.Body>
              <ContentHeader
                icon={ unit3Icon }
                iconAlt="Unit 3 icon"
                studentPageHeader="Unit 3: Carbon in the Future &amp; You"
                unitNumber="3"
                studentPageHeaderSub="Overview"
              />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header className="cardHeader">
                      <h2 className="headerFontBlue">Introduction</h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3 imageBorder"
                            fluid={props.data.image1.childImageSharp.fluid}
                            alt="Unit 3 overview"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight imageBorder rounded mb-4 img-fluid d-none d-md-block"
                        fluid={props.data.image1.childImageSharp.fluid}
                        alt="Unit 3 overview"
                      />

                      <p>
                        You've done a lot in <em>Carbon Connections</em>! In Unit 3, you'll learn about future carbon and make it more relevant to you. How does carbon and climate connect to what you do each day? You'll see some ways, especially as you use climate models in Lessons 3.1 through 3.3 and the Energy Use Monitor in Lesson 3.4.
                      </p>

                      <p>
                        Before you start, recall some big ideas from Units 1 and 2:
                      </p>

                      <ul>
                        <li>Systems have inputs and outputs.</li>
                        <li>Forcings lead to a response, and sometimes the response has a time lag.</li>
                        <li>CO<sub>2</sub> is a forcing on Earth's climate.</li>
                        <li>Photosynthesis and respiration are key processes in your "breathing" biosphere.</li>
                        <li>Carbon moves among sources and sinks.</li>
                        <li>You are a part of the carbon cycle and Earth's climate.</li>
                      </ul>

                      <p>
                        And to be clear, we're talking about climate&mdash;not weather. Climate isn't what you feel each day at a location, such as at your school. Daily changes, like a snowstorm or heat wave, are just <strong>weather</strong> events; they are not <strong>climate</strong> patterns. Climate shows trends that develop over many years.
                      </p>

                      <p>
                        You can see the lessons for this chapter in the Unit 3 Organizer. The main concepts you'll learn in Unit 3 are:
                      </p>

                      <ul>
                        <li>Scientists use several methods to determine the average temperature for Earth.</li>
                        <li>Climate models help you understand the size of some forcings on Earth's temperature.</li>
                        <li>You can reduce your use of electric energy if you understand more about how you use it.</li>
                        <li>You can critically evaluate some types of claims that are made about carbon and climate.</li>
                      </ul>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3 imageBorder"
                            fluid={props.data.image2.childImageSharp.fluid}
                            alt="Unit 3 organizer"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="largeImageCenter imageBorder rounded mb-4 img-fluid d-none d-md-block"
                        fluid={props.data.image2.childImageSharp.fluid}
                        alt="Unit 3 organizer"
                      />

                      <p>
                        In Unit 3, you'll be thinking about how what you do relates to climate. You may wonder if one person can make a difference. But remember, you're not alone! It's not just you, but many thousands of students like you. Together, you can all make a difference.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonNext
                ButtonText="3.1 Your Temperature Connections"
                NextLink="/unit-3/3.1-your-temperature-connections/"
              />

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default Unit3Overview

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "student-pages/unit-3/01_co2_leaves.jpg" }) {
      ...rowImage
    }
    image2: file(relativePath: { eq: "student-pages/unit-3/02_cc_u3_organizer.png" }) {
      ...rowImage
    }
  }
`
